import React from 'react'
import Container from '../components/atoms/Container'
import {graphql} from 'gatsby'
import styled from 'styled-components'
import ArticleCard from '../components/organisms/ArticleCard'
import useWindowSize from '../hooks/useWindowSize'
import ProjectListCard from '../components/organisms/ProjectListCard'

const CardHolder = styled.div`
  border-top: 2px solid ${props => props.theme.colours.blue};

  > h3 {
    color: ${props => props.theme.colours.blue};
    margin-bottom: 3rem;
  }
`

const ProjectGrid = styled.div`
  display: none;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-bottom: 6rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: grid;
  }

  > div {
    margin-bottom: 0 !important;

    h1 {
      ${(props) => props.theme.fluidType(4)};
    }

    > :last-child {
      width: initial !important;
    }
  }
`

const ArticleGrid = styled.div`
  > div:first-child a {
    @media ( ${props => props.theme.breakpoints.md} ) {
      grid-template-columns: 3fr 2fr;
    }
    @media ( ${props => props.theme.breakpoints.lg} ) {
      grid-template-columns: 3fr 1fr;
    }
  }
`

function ArticleListPage({data}) {
    const {nodes} = data.allContentfulArticle
    const size = useWindowSize()

    return (
        <Container>
            <CardHolder><h3>Journal</h3></CardHolder>
            <ProjectGrid>
                <ProjectListCard image={nodes[0].excerptImage} lede={nodes[0].lede} link={'/journal/' + nodes[0].slug}
                                 title={nodes[0].title} key={nodes[0]}/>
                <ProjectListCard image={nodes[1].excerptImage} lede={nodes[1].lede} link={'/journal/' + nodes[1].slug}
                                 title={nodes[1].title} key={nodes[1]}/>
            </ProjectGrid>
            <ArticleGrid>
                {nodes && nodes.map((item, index) => {
                    return (size.width > 992) ? (index > 1 &&
                            <ArticleCard image={item.excerptImage} lede={item.lede} link={item.slug}
                                         title={item.title} key={index}/>) :
                        <ArticleCard image={item.excerptImage} lede={item.lede} link={item.slug}
                                     title={item.title} key={index}/>
                })}
            </ArticleGrid>
        </Container>
    )
}

export const pageQuery = graphql`
    query articleListPageQuery {
        allContentfulArticle(sort: {fields: date, order: DESC}) {
            nodes {
                title
                id
                slug
                excerptImage {
                    gatsbyImageData
                    title
                    description
                }
                lede
            }
        }
    }
`

export default ArticleListPage